import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Blog = ({ pageContent }) => {
  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        Blog Categories
      </Typography>
      {Object.keys(pageContent).map((category) => (
        <Box key={category} sx={{ marginBottom: '30px' }}>
          <Typography variant="h5" sx={{ marginBottom: '10px' }}>
            {category} Posts
          </Typography>
          {pageContent[category].articles.map((post) => (
            <Link key={post.id} to={`/blog/${category}/${post.id}`} style={{ textDecoration: 'none', marginBottom: '10px', display: 'block' }}>
              <Button variant="contained">Read {category} Post {post.id}</Button>
            </Link>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default Blog;
