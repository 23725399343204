import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/footer';
import LazyLoader from './utils/lazyloading';
import Header from './components/header';
import { Box } from '@mui/material'; // Import Box for layout control
import CategoryPage from './common/category-page';
import { pageData } from './data/pagesData';
import Blog from './pages/blog.js';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';


// Lazy load your components
const Home = LazyLoader(React.lazy(() => import('./pages/home')));
const Beauty = LazyLoader(React.lazy(() => import('./pages/beauty')));
const Fashion = LazyLoader(React.lazy(() => import('./pages/fashion')));
const Health = LazyLoader(React.lazy(() => import('./pages/health')));
const Entertainment = LazyLoader(React.lazy(() => import('./pages/entertainment')));
const Lifestyle = LazyLoader(React.lazy(() => import('./pages/lifestyle')));
const Relationship = LazyLoader(React.lazy(() => import('./pages/relationship')));
const Travel = LazyLoader(React.lazy(() => import('./pages/travel')));
const HomeAndGarden = LazyLoader(React.lazy(() => import('./pages/home-and-garden')));
const Automobiles = LazyLoader(React.lazy(() => import('./pages/automobiles')));
const Accessories = LazyLoader(React.lazy(() => import('./pages/fashion/accessories')));
const Men = LazyLoader(React.lazy(() => import('./pages/fashion/men')));
const Women = LazyLoader(React.lazy(() => import('./pages/fashion/women')));
const Fitness = LazyLoader(React.lazy(() => import('./pages/health/fitness')));
const Nutrition = LazyLoader(React.lazy(() => import('./pages/health/nutrition')));
const Wellness = LazyLoader(React.lazy(() => import('./pages/health/wellness')));
const Kids = LazyLoader(React.lazy(() => import('./pages/fashion/kids')));
const TermsAndConditions = LazyLoader(React.lazy(() => import('./pages/terms-and-conditions')));
const AboutUs = LazyLoader(React.lazy(() => import('./pages/about-us')));
const ContactUs = LazyLoader(React.lazy(() => import('./pages/contact-us')));
const Disclaimer = LazyLoader(React.lazy(() => import('./pages/disclaimer')));
const PrivacyPolicy = LazyLoader(React.lazy(() => import('./pages/privacy-policy')));
const AffiliatePolicy = LazyLoader(React.lazy(() => import('./pages/affiliate-policy')));
const CookiePolicy = LazyLoader(React.lazy(() => import('./pages/cookie-policy')));
const Journey = LazyLoader(React.lazy(() => import ('./pages/journey/index.js')))
const ArtAndCulture = LazyLoader(React.lazy(() => import ('./pages/journey/art-and-culture/index.js')))
const Food = LazyLoader(React.lazy(() => import ('./pages/journey/food')))
const Sleep = LazyLoader(React.lazy(() => import ('./pages/journey/sleep')))
const Tech = LazyLoader(React.lazy(() => import ('./pages/tech')))
const Finance = LazyLoader(React.lazy(() => import ('./pages/tech/finance/index.js')))
const BlogPostDetail = LazyLoader(React.lazy(() => import ('./common/blogpost-details/index.js')))
const DynamicPage = LazyLoader(React.lazy(() => import ('./pages/dynamic-page/index.js')))
const SliderCard = LazyLoader(React.lazy(() => import ('./components/slider/index.js')))
const ScrollToTop = LazyLoader(React.lazy(() => import ('./utils/scrolltop.js')))

const Tracking_Id="G-5DZ0BSGXLW"
ReactGA.initialize(Tracking_Id)
function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    // ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    ReactGA.initialize(Tracking_Id);
    ReactGA.send({ hitType: "pageview", page: "/home", title: "Landing Page" });
}, [])
  const pageContent = pageData
  const affiliatePolicyData = pageContent.affiliatePolicyData
  const cookiePolicyData = pageContent.cookiePolicyData
  const privacyPolicy = pageContent.privacyPolicy
  const aboutUsData = pageContent.aboutUsData
  const disclaimerData = pageContent.disclaimerData
  const termsData = pageContent.termsData
  const contactData = pageContent.contactData
//  const recentPosts = pageContent.recentPosts
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", 
      }}
    >
      <HelmetProvider>
      <Router>
        <ScrollToTop/>
        <Header />
        <Box
          sx={{
            flexGrow: 1, // Ensures the content takes the available space
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/beauty" element={<Beauty />} />
              <Route path="/fashion" element={<Fashion />} />
              <Route path="/health" element={<Health />} />
              <Route path="/entertainment" element={<Entertainment />} />
              <Route path="/fashion/lifestyle" element={<Lifestyle />} />
              <Route path="/relationship" element={<Relationship />} />
              <Route path="/travel" element={<Travel />} />
              <Route path="/homeandgarden" element={<HomeAndGarden />} />
              <Route path="/automobiles" element={<Automobiles />} />
              <Route path="/fashion/accessories" element={<Accessories />} />
              <Route path="/fashion/kids" element={<Kids />} />
              <Route path="/fashion/men" element={<Men />} />
              <Route path="/fashion/women" element={<Women />} />
              <Route path="/health/fitness" element={<Fitness />} />
              <Route path="/health/nutrition" element={<Nutrition />} />
              <Route path="/health/wellness" element={<Wellness />} />
              <Route path="/disclaimer" element={<Disclaimer disclaimerData={disclaimerData}/>} />
              <Route path="/termsandconditions" element={<TermsAndConditions termsData={termsData}/>} />
              <Route path="/contactus" element={<ContactUs contactData={contactData}/>} />
              <Route path="/aboutus" element={<AboutUs aboutUsData={aboutUsData}/>} />
              <Route path="/privacypolicy" element={<PrivacyPolicy privacyPolicy={privacyPolicy}/>} />
              <Route path="/affiliatepolicy" element={<AffiliatePolicy affiliatePolicyData={affiliatePolicyData} />} />
              <Route path="/cookiepolicy" element={<CookiePolicy cookiePolicyData={cookiePolicyData}/>} />
              <Route path="/journey" element={<Journey />} />
              <Route path="/journey/food" element={<Food />} />
              <Route path="/journey/artandculture" element={<ArtAndCulture />} />
              <Route path="/journey/sleep" element={<Sleep />} />
              <Route path="/technology" element={<Tech />} />
              <Route path="/finance" element={<Finance />} />

              {/* Blog routes */}
              <Route path="/blog" element={<Blog pageContent={pageContent} />} />
              <Route path="/:category" element={<CategoryPage pageContent={pageContent} />} />
              <Route path="/:category/:title" element={<BlogPostDetail />} />
              <Route path="/:category/:id" element={<SliderCard pageContent={pageContent} />} />
              <Route path="/blog/:category/:id" component={DynamicPage} />
                {/* <Route path="/blog/:category/:id" element={<RecentPost pageContent={pageContent} recentPosts={recentPosts}/>}/> */}
            </Routes>
          </Suspense>
        </Box>
        <Footer />
      </Router>
      </HelmetProvider>
    </Box>
  );
}

export default App;



